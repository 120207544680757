.compositionInfo {
    background-color: #071426;
    border-radius: 14px;
	padding: 2px;
}

.compositionInfo span {
	margin-left: 10px;
}

.compositionRow, .titleRow  {
	color : var(--text-color);
	justify-content: center;
}

.compositionRow .compositionInfo button {
    padding: 5px;
}

.compositionPlaying .p-button-icon {
    animation: playingCompositionAnimation 3s ease 0s infinite normal none;
}



@keyframes playingCompositionAnimation {
	0% {
		opacity: 0.2;
		transform: scale(1);
	}

	80% {
		opacity: 1;
		transform: scale(1.5);
	}

	100% {
		opacity: 0.2;
		transform: scale(1);
	}
  }