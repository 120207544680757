body {
  background-color: var(--surface-ground);
}

.container {
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

h1, h2, h3, h4, h5, p {
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
