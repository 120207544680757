.navbarRow {
    padding: 12px;
}

.navbarRow h1{
    color: var(--text-color);
    line-height: 1em;
    margin: 0 auto 1em;
}

.navbarRow button {
    margin-right: 10px;
}